jQuery(function() {
  $(".select2").select2({theme: 'bootstrap'});
  $(".select2-modal").select2({
    theme: 'bootstrap',
    dropdownParent: $($(".select2-modal").data('modal'))
  });
  // create client through the modal popped up on the projects/new page
  return $(".select2-client").select2({
    theme: 'bootstrap',
    language: {
      noResults: function() {
        let addNewClientLink, addNewClientSting, onClickFunction;
        /* handle key up
        $('.select2-input').on('keyup', function(e) {
          $("#client_name").val(term);
          return $('.select2-input').unbind('keyup');
        });
        */
        addNewClientSting = `+ Add a new client`;
        onClickFunction = "$('.select2-drop').hide(); $('#clientCreationModal').modal();";
        addNewClientLink = '<a href="#" onClick="' + onClickFunction + '">' + addNewClientSting + '</a>';
        return $(addNewClientLink);
      }
    }
  });
});