function loadNextQuestion() {
  var ajax_url, project_id;
  project_id = $('.project-id').val();
  ajax_url = '/projects/' + project_id + '/ethics/next_question';
  return $.ajax({
    type: 'POST',
    url: ajax_url,
    data: {
      ethics_assessment: {
        involves_humans: $('input[name=ethics_assessment\\[involves_humans\\]]:checked').val(),
        is_significant: $('input[name=ethics_assessment\\[is_significant\\]]:checked').val(),
        uses_other_methods: $('input[name=ethics_assessment\\[uses_other_methods\\]]:checked').val(),
        targets_special_groups: $('input[name=ethics_assessment\\[targets_special_groups\\]]:checked').val(),
        source_of_funding: $('input[name=ethics_assessment\\[source_of_funding\\]]:checked').val(),
        contract_required: $('input[name=ethics_assessment\\[contract_required\\]]').checked,
        aims_hypothesis: $('input[name=ethics_assessment\\[aims_hypothesis\\]]').val(),
        methodology: $('input[name=ethics_assessment\\[methodology\\]]').val(),
        significance: $('input[name=ethics_assessment\\[significance\\]]').val(),
        number_participants: $('input[name=ethics_assessment\\[number_participants\\]]').val(),
        number_justification: $('input[name=ethics_assessment\\[number_justfication\\]]').val(),
        selection_criteria: $('input[name=ethics_assessment\\[selection_criteria\\]]').val(),
        procedures_used: $('input[name=ethics_assessment\\[procedures_used\\]]').val(),
        time_commitment: $('input[name=ethics_assessment\\[time_commitment\\]]').val(),
        consent_procedures: $('input[name=ethics_assessment\\[consent_procedures\\]]').val(),
        location: $('input[name=ethics_assessment\\[location\\]]').val(),
        additional_risks: $('input[name=ethics_assessment\\[additional_risks\\]]').val(),
        strategies_for_risk: $('input[name=ethics_assessment\\[strategies_for_risk\\]]').val(),
        other_issues: $('input[name=ethics_assessment\\[other_issues\\]]').val()
      },
      other_source_of_funding: $('input[name=other_source_of_funding]').val()
    },
    dataType: 'script'
  });
};

$(document).ready(function() {
  return $('.ethics-question-radio').click(function(event) {
    return loadNextQuestion();
  });
});

//unfortunately, this code must be duplicated in the view folder. Make sure any changes are reflected there.
