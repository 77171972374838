import Highcharts from 'highcharts';

export function employeeHoursDefaultOptions() {
  return {
    credits: { enabled: false },

    yAxis: {
      title: { text: 'hours' },
    },

    legend: {
      enabled: false,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      x: -10,
      y: 100,
      borderWidth: 0
    },

    plotOptions: {
      column: {
        stacking: 'normal',
      }
    },

    tooltip: {
      formatter: function() {
        return '<b>'+ this.series.name + '</b>'
          + '<br/><b>' + this.x + '</b> '+ this.y + ' hours</b>'
          + (this.total == undefined ? '' : ('<br/><b>Total: </b>' + this.total));
      }
    }
  };
};

export function employeeHoursChart(target, dates, series, title) {

  // Determine how often to display the date on the x axis
  let tickInterval = null;
  if (dates.length > 15) { tickInterval = 3; }

  return new Highcharts.Chart($.extend({}, employeeHoursDefaultOptions(), {
    chart: {
      renderTo: target,
      type: 'column',
      marginRight: 130,
      marginBottom: 65
    },

    title: { text: title, x: -20 },

    xAxis: {
      labels: { align: 'left', staggerLines: 2 },
      categories: dates,
      tickInterval: tickInterval,
    },

    series: series
  }));
};

