jQuery(function() {

  $('.chevron-toggle').on('click', function() {
    var link;
    link = $(this);
    if (link.data('chevron') === 'multiple') {
      return $(link.data('chevron-target')).find('i').toggleClass('hidden');
    } else {
      return link.find('i').toggleClass('hidden');
    }
  });
  let queries = window.queries;
  if ((queries) && (queries.toggle != null) && queries.toggle === "chevron") {
    if ((queries.type != null) && (queries.year != null)) {
      $(`a[href='#${queries.type}Breakdown']`).click();
      $(`a[href='#${queries.type}${queries.year}Breakdown']`).click();
      return true;
    }
  }
});
