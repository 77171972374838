$(document).ready(function() {
  var bulk_action_switcher;
  $('.planner').removeClass('hidden');
  $('.planner-financial-button').on('click', function(event) {
    var button;
    button = $(this);
    return button.find('span').toggleClass('hidden');
  });
  $('.planner-summary-button').on('click', function(event) {
    var button, summary;
    button = $(this);
    summary = button.data('summary');
    switch (summary) {
      case "time":
        $('.planner .planner-cost').hide();
        $('.planner .planner-time').show();
        break;
      case "money":
        $('.planner .planner-time').hide();
        $('.planner .planner-cost').show();
    }
    $('.planner-summary-button').removeClass('btn-primary');
    $('.planner-summary-button').addClass('btn-default');
    return button.addClass('btn-primary');
  });
  $('.planner-view-button').on('click', function(event) {
    var button, view;
    button = $(this);
    view = button.data('view');
    switch (view) {
      case "full":
        $('.planner tbody tr').show();
        break;
      case "activities":
        $('.planner tbody tr').hide();
        $('.planner tbody tr.activity').show();
        break;
      case "tasks":
        $('.planner tbody tr').hide();
        $('.planner tbody tr.task').show();
    }
    $('.planner-view-button').removeClass('btn-primary');
    $('.planner-view-button').addClass('btn-default');
    return button.addClass('btn-primary');
  });
  $('#create_forward_plan_repeat').change(function() {
    return $('#create_forward_plan_week_ending').prop('disabled', !this.checked);
  });
  $('#forwardPlanCreateModal').on('show.bs.modal', function(event) {
    var link, modal;
    link = $(event.relatedTarget);
    modal = $(this);
    if (link[0]) {
      return $.getJSON("/employees/" + link.data('employee-id') + "/stats_for_week?week_starting=" + link.data('week-starting'), function(data) {
        var week_ending;
        modal.find('.forward-plan-employee').text(data.employee);
        modal.find('.forward-plan-week').text(data.week);
        modal.find('.forward-plan-expected').text(data.expected);
        modal.find('.forward-plan-planned').text(data.planned);
        modal.find('.forward-plan-unavailable').text(data.unavailable);
        modal.find('.forward-plan-available').text(data.available);
        modal.find('#create_forward_plan_task_assignment_id').val(link.data('task-assignment-id'));
        modal.find('#create_forward_plan_week_starting').val(link.data('week-starting'));
        // pre-calculating this in Ruby is extremely costly, so we do it only on the click event
        week_ending = new Date(link.data('week-starting'));
        week_ending.setDate(week_ending.getDate() + 7);
        return modal.find('#create_forward_plan_week_ending').datepicker('setStartDate', week_ending);
      });
    }
  });
  $('#forwardPlanCreateModal').on('hide.bs.modal', function(event) {
    var link, modal, week_ending;
    link = $(event.relatedTarget);
    modal = $(this);
    if (link[0]) {
      modal.find('#create_forward_plan_planned_hours').val("");
      modal.find('#create_forward_plan_repeat').prop("checked", false);
      modal.find('#create_forward_plan_task_assignment_id').val("");
      modal.find('#create_forward_plan_week_starting').val("");
      week_ending = "";
      return $('#create_forward_plan_week_ending').prop('disabled', true);
    }
  });
  $('#forwardPlanPlannerModal').on('show.bs.modal', function(event) {
    var link, modal;
    link = $(event.relatedTarget);
    modal = $(this);
    return modal.find('.modal-content').load("/forward_plans/" + link.data('forward-plan-id') + "/planner_modal");
  });
  $('#activityPlannerModal').on('show.bs.modal', function(event) {
    var link, modal;
    link = $(event.relatedTarget);
    if (link.length > 0) {
      modal = $(this);
      return modal.find('.modal-content').load("/activities/" + link.data('activity-id') + "/planner_modal", function(response, status, xhr) {
        return window.initializeDatepicker(modal.find('.datepicker'));
      });
    }
  });
  $('#taskPlannerModal').on('show.bs.modal', function(event) {
    var link, modal;
    link = $(event.relatedTarget);
    modal = $(this);
    return modal.find('.modal-content').load("/tasks/" + link.data('task-id') + "/planner_modal", function(response, status, xhr) {
      return window.initializeDatepicker(modal.find('.datepicker'));
    });
  });
  $('#taskAssignmentPlannerModal').on('show.bs.modal', function(event) {
    var link, modal;
    link = $(event.relatedTarget);
    modal = $(this);
    return modal.find('.modal-content').load("/task_assignments/" + link.data('task-assignment-id') + "/planner_modal");
  });
  bulk_action_switcher = function(target, selected) {
    $(target + ' .select-activity').hide();
    $(target + ' .select-task').hide();
    $(target + ' .select-employee').hide();
    $(target + ' .select-assignment').hide();
    switch (selected) {
      case "activity":
        return $(target + ' .select-activity').show();
      case "employee":
        return $(target + ' .select-employee').show();
      case "task":
        return $(target + ' .select-task').show();
      case "assignment":
        return $(target + ' .select-assignment').show();
    }
  };
  $('#moveForwardPlanModal').on('show.bs.modal', function(event) {
    var selected;
    selected = $('#moveForwardPlanModal .bulk-action-element').val();
    return bulk_action_switcher('#moveForwardPlanModal', selected);
  });
  $('#massDeleteForwardPlanModal').on('show.bs.modal', function(event) {
    var selected;
    selected = $('#massDeleteForwardPlanModal .bulk-action-element').val();
    return bulk_action_switcher('#massDeleteForwardPlanModal', selected);
  });
  return $('.bulk-action-element').on('change', function(event) {
    var modal, select_box;
    select_box = $(event.currentTarget);
    modal = select_box.closest('.modal');
    return bulk_action_switcher('#' + modal.attr('id'), select_box.val());
  });
});