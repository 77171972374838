jQuery(function() {
  var i, key_value, len, query, ref, results, search;
  window.queries = {};
  search = window.location.search.substr(1);
  if (search !== "") {
    ref = search.split('&');
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      query = ref[i];
      key_value = query.split('=');
      results.push(window.queries[key_value[0].toString()] = key_value[1].toString());
    }
    return results;
  }
});
