/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import {} from 'jquery-ujs';
require('bootstrap-sass');
require('bootstrap-datepicker');
require('bootstrap-fileinput');
require('bootstrap-validator');
window.JSZip = require('jszip'); //a requirement for the Excel datatables button
//we need to assign it to this variable because that's where DataTables looks for it
require('datatables.net');
require('datatables.net-bs');
require('datatables.net-buttons');
require('datatables.net-buttons/js/buttons.colVis');
require('datatables.net-buttons/js/buttons.html5');
require('datatables.net-buttons-bs');
//require micro-event, maybe?
require('select2');
require('jquery-ui/ui/widgets/draggable');

import '../src/javascripts/legacy/attachment.js';
import '../src/javascripts/legacy/chevron-toggle.js';
import '../src/javascripts/legacy/costing_template.js';
import '../src/javascripts/legacy/datatables.init.js';
import '../src/javascripts/legacy/datepicker.js';
import '../src/javascripts/legacy/ethics_assessments.js';
import {employeeHoursDefaultOptions, employeeHoursChart} from '../src/javascripts/legacy/employee_hours';
window.employeeHoursDefaultOptions = employeeHoursDefaultOptions;
window.employeeHoursChart = employeeHoursChart;
import '../src/javascripts/legacy/expenses.js';
import '../src/javascripts/legacy/financials.js';
import '../src/javascripts/legacy/highlighter.js';
import '../src/javascripts/legacy/notes.js';
import '../src/javascripts/legacy/planner.js';
import '../src/javascripts/legacy/project_hours_pipeline_report.js';
import '../src/javascripts/legacy/projects.js';
import '../src/javascripts/legacy/queries.js';
import '../src/javascripts/legacy/search.js';
import '../src/javascripts/legacy/toggle_effects.js';
import '../src/javascripts/legacy/modals.js';
import '../src/javascripts/legacy/select2.js';

window.$ = $;
