$(document).ready(function() {
  var AdvancedSearch, advanced_search;
  $('#navigationSearch').popover({
    html: true,
    placement: 'bottom',
    title: 'Search PIMMS',
    content: function() {
      return $("#search").children().clone();
    }
  });
  if ($('.js-advanced-search').length > 0) {
    AdvancedSearch = class AdvancedSearch {
      constructor() {
        this.queries = {};
      }

      rebuild() {
        var id, keyword, query, value, values;
        value = (function() {
          var ref, results;
          ref = this.queries;
          results = [];
          for (id in ref) {
            query = ref[id];
            values = (function() {
              var i, len, ref1, results1;
              ref1 = query.value.toString().split(",");
              results1 = [];
              for (i = 0, len = ref1.length; i < len; i++) {
                keyword = ref1[i];
                if (keyword.indexOf(' ') >= 0 && keyword.indexOf('"') < 0 && !(query["prefix"] === "proposal_verdict_date" && keyword.indexOf('TO') >= 0)) {
                  keyword = '"' + keyword.trim() + '"';
                }
                results1.push(`${query["prefix"]}:${keyword}`);
              }
              return results1;
            })();
            results.push(values.join(" "));
          }
          return results;
        }).call(this);
        return $('#query').val(value.join(" "));
      }

      update(id, value, prefix) {
        if (value === "") {
          delete this.queries[id];
        } else {
          this.queries[id] = {
            prefix: prefix,
            value: value
          };
        }
        return this.rebuild();
      }

    };
    advanced_search = new AdvancedSearch;
    $('.js-advanced-search[type="text"]').on('input', function() {
      var input;
      input = $(this);
      return advanced_search.update(input.attr('id'), input.val().trim(), input.data('search-prefix'));
    });
    return $('.js-advanced-search[type="checkbox"]').on('change', function(event) {
      var input;
      input = $(this);
      return advanced_search.update(input.attr('id'), (this.checked ? "" : 'true'), input.data('search-prefix'));
    });
  }
});
