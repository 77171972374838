$(function() {
  var selectBoxes, toggleCheckedResearchAreas;
  selectBoxes = [$('#project_client_project_manager_id'), $('#project_client_lead_contact_id'), $('#project_client_legal_contact_id')];
  $('#project_client_ids').on("change", function(event) {
    var j, len, matchingGroups, results, selectBox;
    if (event.added) {
      return $.getJSON(`/clients/${event.added.id}/contacts`, function(contacts) {
        var contact, j, k, len, len1, options, results, selectBox;
        results = [];
        for (j = 0, len = selectBoxes.length; j < len; j++) {
          selectBox = selectBoxes[j];
          options = "";
          for (k = 0, len1 = contacts.length; k < len1; k++) {
            contact = contacts[k];
            options += `<option value='${contact.id}'>${contact.full_name}</option>`;
          }
          results.push(selectBox.append(`<optgroup label='${_.escape(event.added.text)}'>${options}</optgroup>`));
        }
        return results;
      });
    } else if (event.removed) {
      results = [];
      for (j = 0, len = selectBoxes.length; j < len; j++) {
        selectBox = selectBoxes[j];
        matchingGroups = selectBox.find('optgroup').filter(function() {
          return $(this).attr('label') === event.removed.text;
        });
        results.push(matchingGroups.remove());
      }
      return results;
    }
  });
  $('#project_primary_research_area_id').removeAttr('disabled');
  toggleCheckedResearchAreas = function() {
    $('#project_primary_research_area_id option').attr('disabled', true);
    $('#project_research_areas_input input:checked').map(function(i, e) {
      return $(e).val();
    }).each(function(i, e) {
      return $(`#project_primary_research_area_id option[value='${e}']`).removeAttr('disabled');
    });
    if ($("#project_primary_research_area_id option:selected").attr('disabled')) {
      return $("#project_primary_research_area_id option:first").attr('selected', true);
    }
  };
  toggleCheckedResearchAreas();
  $('#project_research_areas_input input[type="checkbox"]').change(toggleCheckedResearchAreas);
  return $('.project-notes-link').on('click', function(event) {
    $('#project').toggleClass('hidden');
    return $('.actual-project-notes-link').parent().toggleClass('active');
  });
});
