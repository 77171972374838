$(document).ready(function() {
  $('#costing_template_csv').fileinput({
    allowedFileExtensions: ['csv'],
    showPreview: false,
    showUpload: false
  });
  return $('#save_project').click(function() {
    return $('#save_project').addClass("disabled");
  });
});
