import { checkReportProgress, datatableOptions, loadError } from './datatables.init';
import Highcharts from 'highcharts';

function fetch_pipeline_records(recordsId) {
  let datatableDiv = document.querySelector('#projects-datatable');
  let filename = "PIMMS_ProjectHoursPipeline";
  if (datatableDiv !== null && ('filename' in datatableDiv.dataset)) {
    filename = datatableDiv.dataset.filename;
  };

  checkReportProgress(recordsId, filename, load_project_hours_pipeline, loadError);
}

function shadeNonPipelineRows(row, data) {
  var statusColumn = $('#pipeline_results').data('status-column');
  if (data[statusColumn].charAt(0) === '[') {
    $('td', row).css('background-color', '#fceedb')
  }
}

function load_project_hours_pipeline(data, filename) {
  $('#pipeline_loading').hide();
  $('#pipeline_results').show();

  var statusColumn = $('#pipeline_results').data('status-column');

  Highcharts.chart('project_hours_pipeline_graph', data['graph_data']);

  let targetsDatatable = $.extend({}, datatableOptions(filename), JSON.parse(data['table_data']));
  targetsDatatable["rowCallback"] = shadeNonPipelineRows;
  targetsDatatable["order"] = [[statusColumn, "asc"], [0, "asc"]];
  $('#projects-datatable').DataTable(targetsDatatable);

}

window.fetch_pipeline_records = fetch_pipeline_records;

