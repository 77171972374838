jQuery(function() {
  $('#timesheetWeekStarting').datepicker({
    daysOfWeekDisabled: [0, 2, 3, 4, 5, 6],
    format: 'yyyy-mm-dd',
    defaultViewDate: {
      year: $('#timesheetWeekStarting').data('default-view-date-year'),
      month: $('#timesheetWeekStarting').data('default-view-date-month'),
      day: $('#timesheetWeekStarting').data('default-view-date-day')
    },
    orientation: 'auto left',
    todayBtn: true,
    todayHighlight: true
  }).on("changeDate", function(event) {
    return window.location = "/employees/" + $('#timesheetWeekStarting').data('employee-id') + "/timesheets/" + event.format('yyyy-mm-dd');
  });
  $('#professionalDevelopmentYear').datepicker({
    defaultViewDate: {
      year: $('#professionalDevelopmentYear').data('default-view-date-year')
    },
    minViewMode: 2
  }).on("changeDate", function(event) {
    return window.location = "/employees/" + $('#professionalDevelopmentYear').data('employee-id') + "/professional_development?year=" + event.format('yyyy');
  });
  window.initializeDatepicker = function(elements) {
    var args, element, i, len, orientation, results;
    results = [];
    for (i = 0, len = elements.length; i < len; i++) {
      element = elements[i];
      element = $(element);
      args = {
        autoclose: true,
        format: 'yyyy-mm-dd',
        orientation: 'auto left',
        todayBtn: true,
        todayHighlight: true
      };
      if (element.hasClass('datepicker-month')) {
        args.startView = 1;
        args.minViewMode = 1;
      }
      if (orientation = element.data("datepicker-orientation")) {
        args.orientation = orientation;
      }
      if (element.hasClass('datepicker-monday')) {
        args['daysOfWeekDisabled'] = [0, 2, 3, 4, 5, 6];
      }
      results.push(element.datepicker(args));
    }
    return results;
  };
  return window.initializeDatepicker($('input[class*="datepicker"], input[type="text"][name*="date"]'));
});
