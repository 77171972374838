$(document).ready(() => {
    $('#expense_category').on('change', fetchFilteredBudgets);
    $('#expense_paid_on').on('change', fetchFilteredBudgets);
  }
);

function fetchFilteredBudgets() {
  let paid_on = $('#expense_paid_on').val();
  let category = $('#expense_category option:selected').val();
  let budget_id = $('#expense_budget_id').val();

  let url = $('.expenses-form').data('budgetFilterUrl');
  return $.ajax({
    url: url,
    type: 'GET',
    dataType: 'script',
    data: {
      expense: {
        category, paid_on, budget_id
      }
    }
  });
};
