$(document).ready(function() {
  return $('[data-toggle="popover"]').popover({
    container: 'body'
  });
});

$(document).ready(() => {
  $('a[data-toggle="tab"]').on('show.bs.tab', (e) => {
    let link = $(this);

    if (group = $(link.parent('.btn-group'))) {
      group.find('.active').removeClass('active');
      link.addClass('active');
    };
  });

  $('a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
    tab = $(e.target);

    $("#{tab.attr('href')} .highchart").each((index, chart) => {
      $(chart).highcharts().reflow();
    });
  });
 });

$(document).ready(() => {
  $('[data-toggle="tooltip"]').tooltip({ container: 'body' })
});
